<template>
  <div>
    <b-modal
      id="return-to-stock"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeToStock')"
      hide-footer
      class="custom-modal"
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-select-1"
          :label="$t('SelectedArticles')"
          label-for="select-1"
        >
          <ul>
            <li
              v-for="article in articleNumber"
              :key="article"
            >
              {{ article }}
            </li>
          </ul>
        </b-form-group>
        <div>{{ $t('SalesPerson') }}: {{ salesPersonFullName }}</div>
        <hr style="color: lightgray;">
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="primary"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['articleNumber', 'salesPersonFullName'],
  data() {
    return {

    }
  },

  computed: {
    ...mapGetters([]),

  },
  mounted() {

  },
  methods: {
    ...mapActions([]),

    onSubmit() {
      this.$emit('submitForm')
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {

    },
  },
}
</script>

<style lang="scss" scoped>
</style>
