<template>
  <div class="mainn">
    <ReturnProcess @selectSalesPerson="setSalesPerson" />
    <ReturnProcessToStock :sales-person-full-name="salesPersonFullName" />
  </div>
</template>

<script>
import ReturnProcess from '@/components/orders/ReturnProcess.vue'
import ReturnProcessToStock from '@/components/orders/ReturnProcessToStock.vue'

export default {
  components: {
    ReturnProcess,
    ReturnProcessToStock,
  },
  data() {
    return {
      salesPersonFullName: '',
    };
  },
  methods: {
    setSalesPerson(name) {
      this.salesPersonFullName = name;
    },
  },
}


</script>
<style>
.mainn{
  padding: 75px 15px 15px 120px;
    display: flex;
    gap: 30px;
}

</style>
